import BaseService from '@/services/BaseService'
import axios from 'axios'
import utility from '@/common/utility'
import auth from '@/common/auth'

let resourceName = 'a15'
let batchAddA15sUrl = 'BatchAddA15s'
let deleteUrl = 'DeleteA15'
let approvalUrl = 'EditA15Num'
let batchLockUrl = 'BatchEditIsLock'
let batchPictureUrl = 'BatchEditPictureGuid'
let batchDeleteUrl = 'BatchDelA15s'
let getRowCountUrl = 'GetA15RowCountByPeriodGuid'
let getSummaryRowCountUrl = 'GetHeTongDuanA15RowCountByPeriodGuid'
let editImportantUrl = 'UpdateZhongYao'
let getCreateUserListUrl = 'GetCreateUserList'

class A15Service extends BaseService {
  batchAdd (periodName, a15List) {
    return axios.post(utility.getFullUrl(this.resourceName, batchAddA15sUrl, periodName), a15List, {
      headers: {
        'Authorization': utility.getAuthHeader(auth.getToken())
      }
    })
  }

  approval (a15Guid, identityGuid, num, sortId, examinationGuid, periodName) {
    return axios.post(utility.getFullUrl(this.resourceName, approvalUrl), utility.getFormData({
      a15Guid,
      identityGuid,
      num,
      sortId,
      examinationGuid,
      periodName
    }), {
      headers: {
        'Authorization': utility.getAuthHeader(auth.getToken())
      }
    })
  }

  batchLock (examinationGuid, a15GuidList, isLocked) {
    return axios.post(utility.getFullUrl(this.resourceName, batchLockUrl, examinationGuid), a15GuidList.map(a15Guid => {
      return {
        a15Guid,
        isLock: isLocked
      }
    }), {
      headers: {
        'Authorization': utility.getAuthHeader(auth.getToken())
      }
    })
  }

  batchPicture (a15GuidList, pictureGuid) {
    return axios.post(utility.getFullUrl(this.resourceName, batchPictureUrl), a15GuidList.map(a15Guid => {
      return {
        a15Guid,
        pictureGuid: pictureGuid
      }
    }), {
      headers: {
        'Authorization': utility.getAuthHeader(auth.getToken())
      }
    })
  }

  batchAddPicture (a15PictureList) {
    return axios.post(utility.getFullUrl(this.resourceName, batchPictureUrl), a15PictureList, {
      headers: {
        'Authorization': utility.getAuthHeader(auth.getToken())
      }
    })
  }

  batchDelete (a15GuidList, examinationGuid) {
    return axios.post(utility.getFullUrl(this.resourceName, batchDeleteUrl), a15GuidList.map(item => {
      return {
        a15Guid: item,
        examinationGuid
      }
    }), {
      headers: {
        'Authorization': utility.getAuthHeader(auth.getToken())
      }
    })
  }

  // override
  delete (a15Guid, examinationGuid, periodName) {
    return axios.post(utility.getFullUrl(this.resourceName, deleteUrl), utility.getFormData({
      a15Guid,
      examinationGuid,
      periodName
    }), {
      headers: {
        'Authorization': utility.getAuthHeader(auth.getToken())
      }
    })
  }

  getRowCount (periodGuid) {
    return axios.post(utility.getFullUrl(this.resourceName, getRowCountUrl, periodGuid), null, {
      headers: {
        'Authorization': utility.getAuthHeader(auth.getToken())
      }
    })
  }

  getSummaryRowCount (periodGuid) {
    return axios.post(utility.getFullUrl(this.resourceName, getSummaryRowCountUrl, periodGuid), null, {
      headers: {
        'Authorization': utility.getAuthHeader(auth.getToken())
      }
    })
  }

  editImportant (a15) {
    return axios.post(utility.getFullUrl(this.resourceName, editImportantUrl), a15, {
      headers: {
        'Authorization': utility.getAuthHeader(auth.getToken())
      }
    })
  }

  getCreateUserList (examinationGuid) {
    return axios.get(utility.getFullUrl(this.resourceName, getCreateUserListUrl), {
      params: {
        examinationGuid
      },
      headers: {
        'Authorization': utility.getAuthHeader(auth.getToken())
      }
    })
  }
}

const a15Service = new A15Service(resourceName)

export default a15Service
