import BaseService from '@/services/BaseService'
import axios from 'axios'
import utility from '@/common/utility'
import auth from '@/common/auth'

let resourceName = 'tender'
let listByUserUrl = 'GetListPageByUserGuid'
let listProjectByUserUrl = 'GetProjectListByUserGuid'
let listByUserAndProjectUrl = 'GetListPageByUserGuidandProjectGuid'
let listContractTenderByUserUrl = 'GetHTTenderListByUserGuid'

class TenderService extends BaseService {
  listProjectByUser (userGuid) {
    return axios.get(utility.getFullUrl(this.resourceName, listProjectByUserUrl), {
      params: {
        userGuid
      },
      headers: {
        'Authorization': utility.getAuthHeader(auth.getToken())
      }
    })
  }

  listByUserAndProject (userGuid, projectGuid) {
    return axios.get(utility.getFullUrl(this.resourceName, listByUserAndProjectUrl), {
      params: {
        userGuid,
        projectGuid
      },
      headers: {
        'Authorization': utility.getAuthHeader(auth.getToken())
      }
    })
  }

  listByUser (userGuid) {
    return axios.get(utility.getFullUrl(this.resourceName, listByUserUrl), {
      params: {
        userGuid
      },
      headers: {
        'Authorization': utility.getAuthHeader(auth.getToken())
      }
    })
  }

  listContractTenderByUser (userGuid) {
    return axios.get(utility.getFullUrl(this.resourceName, listContractTenderByUserUrl), {
      params: {
        userGuid
      },
      headers: {
        'Authorization': utility.getAuthHeader(auth.getToken())
      }
    })
  }

  labListByUser (userGuid) {
    return axios.get(utility.getFullUrl(this.resourceName, 'GetJianLiListPageByUserGuid'), {
      params: {
        userGuid
      },
      headers: {
        'Authorization': utility.getAuthHeader(auth.getToken())
      }
    })
  }
}

const tenderService = new TenderService(resourceName)

export default tenderService
