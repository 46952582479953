<template>
  <div class="report-display">
    <my-search-bar>
      <el-form :inline="true" class="mt-2">
        <el-form-item>
          <el-select
            v-model="tenderGuid"
            placeholder="标段"
            clearable>
            <el-option
              v-for="tender in tenders"
              :key="tender.tenderGuid"
              :label="tender.tenderName"
              :value="tender.tenderGuid">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select v-model="periodGuid" placeholder="工期" clearable>
            <el-option
              v-for="period in periods"
              :key="period.periodGuid"
              :value="period.periodGuid"
              :label="period.periodAlias">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </my-search-bar>

    <div
      v-if="reports.length"
      >
      <div
        v-for="item of keys"
        :key="item.key"
        class="report-wrapper">
        <template v-if="reportMap[item.key] && reportMap[item.key].length">
          <div class="title">
            {{ item.name }}
          </div>
          <div class="report-item"
            v-for="report in reportMap[item.key]"
            :key="report.reportGuid"
            >
            <div class="bg-info" @click="handleClick(report, 'pdf', true)">
              <i :class="report.iconClass"></i>
            </div>
            <div class="buttons-wrapper">
              <span style="font-size: 14px; padding-bottom: 2px;" @click="handleClick(report, 'pdf', true)">
                {{ report.reportAlias }} {{ report.reportName }} <span v-if="report.reportAlias === 'A15'">{{ pageLabel }}</span>
              </span>
              <div class="icons btn-group btn-group-sm">
                <button class="btn" @click="handleClick(report, 'pdf')">
                  <i class="fas fa-file-pdf"></i>
                </button>
                <button class="btn" @click="handleClick(report, 'docx')">
                  <i class="fas fa-file-word"></i>
                </button>
                <button class="btn" @click="handleClick(report, 'xlsx')">
                  <i class="fas fa-file-excel"></i>
                </button>
                <button class="btn" @click="handleClick(report, 'png')">
                  <i class="fas fa-file"></i>
                </button>
                <button v-if="report.reportGroup === 'P'" class="btn" style="padding: 0;">
                  <el-dropdown trigger="click" size="mini" @command="handleCommand" style="width: 100%; height: 100%; line-height: 27px;">
                    <div style="width: 100%; height: 100%;">
                      <i class="el-icon-caret-bottom"></i>
                    </div>
                    <el-dropdown-menu>
                      <el-input-number :controls="false" v-model="report.pageIndex" type="text" min="0" step="1" :step-strictly="true" size="small" placeholder="第几册">
                      </el-input-number>
                      <span> / </span>
                      <el-input-number :controls="false" v-model="report.pageSize" type="text" min="0" step="1" :step-strictly="true" size="small" placeholder="共几册">
                      </el-input-number>
                    </el-dropdown-menu>
                  </el-dropdown>
                </button>
                <button v-if="report.reportAlias === 'A15'" class="btn" style="padding: 0;">
                  <el-dropdown trigger="click" size="mini" @command="handleCommand" style="width: 100%; height: 100%; line-height: 27px;">
                    <div style="width: 100%; height: 100%;">
                      <i class="el-icon-caret-bottom"></i>
                    </div>
                    <el-dropdown-menu>
                      <el-dropdown-item
                        :command="page.pageIndex"
                        v-for="page of pageList"
                        :key="page.pageIndex">
                        {{ page.label }}
                      </el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </button>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
    <my-dialog
      ref="myDialog"
      :title="dialogTitle"
      :hide-ok="true"
      cancel-text="关闭"
      body-height="700px"
      size="xl">
      <iframe ref="myIFrame" :src="url" width="100%" height="100%" scrolling="no" frameborder="0"></iframe>
    </my-dialog>
  </div>
</template>

<script>
import reportService from '@/services/reportService'
import examinationService from '@/services/examinationService'
import tenderService from '@/services/tenderService'
import a15Service from '@/services/a15Service'
import MySearchBar from '@/components/MySearchBar/MySearchBar'
import MyDialog from '@/components/MyDialog/MyDialog'
import utility from '@/common/utility'
import config from '@/config'
import auth from '@/common/auth'
import PageInfo from '@/support/PageInfo'

export default {
  name: 'ReportDisplay',
  components: {
    MyDialog,
    MySearchBar
  },
  data () {
    return {
      tenderGuid: '',
      periodGuid: '',
      reports: [],
      reportMap: {},
      tenders: [],
      periods: [],
      pageList: [],
      pageIndex: 1,
      pageSize: 100,
      url: '',
      dialogTitle: '查看报表',
      keys: [
        {
          key: 'Z',
          name: 'Z表'
        },
        {
          key: 'S',
          name: 'S表'
        }
      ]
    }
  },
  watch: {
    tenderGuid (newVal) {
      this.periods = []
      this.periodGuid = ''
      this.reports = []
      this.url = ''
      if (newVal) {
        this._getPeriods(newVal)
        this._getReports(this._getReportTemplateGuid(newVal))
      }
    },
    periodGuid (newVal) {
      this.pageList = []
      if (newVal) {
        this.getA15RowCount(newVal)
      }
    }
  },
  computed: {
    pageLabel () {
      if (this.pageList && this.pageList.length && this.pageIndex) {
        let page = this.pageList.find(page => page.pageIndex === this.pageIndex)
        if (page) {
          return `(${page.label})`
        }
      }
      return ''
    }
  },
  methods: {
    handleClick (report, format, inline = false) {
      if (!this.periodGuid) {
        this.$message({
          type: 'error',
          message: '请选择工期'
        })
        return
      }
      let timestamp = utility.getCurrentTimestamp()
      let token = utility.getMd5(`${this.tenderGuid}-${timestamp}-${config.fastReportKey}`).toUpperCase()

      let pageIndex = 0
      let pageSize = 0

      if (report.reportAlias === 'A15') {
        pageIndex = this.pageIndex
        pageSize = this.pageIndex === 0 ? 0 : this.pageSize
      } else if (report.reportGroup === 'P') {
        pageIndex = report.pageIndex
        pageSize = report.pageSize
      }

      this.url = `${config.reportHost}/report?format=${format}&inline=${inline}&period=${this.periodGuid}&report=${report.reportGuid}&timestamp=${timestamp}&parameter=${token}&pageindex=${pageIndex}&pagesize=${pageSize}`
      console.log('this.url', this.url)
      // this.url = `${config.reportHost}/reportjhtj?format=${format}&inline=${inline}&examinations=3f6daab0-6b32-11ea-9749-ff6482a309df&report=${report.reportGuid}&timestamp=${timestamp}&parameter=${token}`
      if (inline) {
        this.dialogTitle = report.reportAlias + ' ' + report.reportName
        this.$refs.myDialog.open()
      } else {
        window.open(this.url, '_blank')
      }
    },
    _getReportTemplateGuid (tenderGuid) {
      for (let i = 0; i < this.tenders.length; ++i) {
        if (this.tenders[i].tenderGuid === tenderGuid) {
          return this.tenders[i].reportTemplateGuid
        }
      }
      return ''
    },
    _getReports (reportTemplateGuid) {
      reportService.list(new PageInfo({ pageSize: config.bigPageSize, pageIndex: 1, fieldOrder: 'reports.sortId asc' }), { reportTemplateGuid: reportTemplateGuid, reportType: '工程计量', isPublic: '1' })
        .then(res => {
          if (res.data.code === 1) {
            this.reportMap = {}
            res.data.data.forEach(report => {
              if (report.reportGroup && report.isPublic === 0 && config.finalSubmitIdentities.indexOf(auth.getUserInfo().identityGuid) === -1) {
                return
              }
              if (!this.reportMap[report.reportGroup]) {
                this.reportMap[report.reportGroup] = []
              }
              this.reportMap[report.reportGroup].push(report)
            })
            for (let key in this.reportMap) {
              this.reportMap[key].sort((a, b) => a.sortId - b.sortId)
            }
            this.reports = res.data.data.map(item => {
              item.pageIndex = 0
              item.pageSize = 0
              return item
            })
          } else {
            this.$message({
              type: 'error',
              message: res.data.msg
            })
          }
        })
        .catch(err => {
          console.log(err)
          this.$message({
            type: 'error',
            message: '数据加载失败！'
          })
        })
    },
    _getTenders () {
      tenderService.labListByUser(auth.getUserInfo().userGuid)
        .then(res => {
          if (res.data.code === 1) {
            this.tenders = res.data.data
          } else {
            this.$message({
              type: 'error',
              message: res.data.msg
            })
          }
        })
        .catch(err => {
          this.$message({
            type: 'error',
            message: '获取数据失败！'
          })
          console.log(err.request.response)
        })
    },
    _getPeriods (tenderGuid) {
      examinationService.getAddedPeriods(tenderGuid)
        .then(res => {
          if (res.data.code === 1) {
            this.periods = res.data.data
          } else {
            this.$message({
              type: 'error',
              message: res.data.msg
            })
          }
        })
        .catch(err => {
          this.$message({
            type: 'error',
            message: '获取数据失败！'
          })
          window.concole.log(err.request.response)
        })
    },
    getA15RowCount (periodGuid) {
      a15Service.getRowCount(periodGuid)
        .then(res => {
          if (res.data.code === 1) {
            let rowCount = parseFloat(res.data.rowCount)
            let totalPages = Math.ceil(rowCount / this.pageSize)
            this.pageList = []
            for (let i = 1; i < totalPages; ++i) {
              this.pageList.push({
                pageIndex: i,
                label: `${(i - 1) * this.pageSize + 1}-${i * this.pageSize}`
              })
            }
            if (rowCount > 0) {
              this.pageList.push({
                pageIndex: totalPages,
                label: `${(totalPages - 1) * this.pageSize + 1}-${rowCount}`
              })
            }
            if (rowCount <= 300) {
              this.pageList.push({
                pageIndex: 0,
                label: '全部'
              })
            }
            this.pageIndex = 1
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    handleCommand (command) {
      this.pageIndex = command
    }
  },
  created () {
    this._getTenders()
  }
}
</script>

<style scoped lang="scss">
.report-wrapper {
  clear: both;
  display: grid;
  grid-template-columns: repeat(auto-fit, 280px);
  grid-gap: 3px 30px;
  margin-bottom: 10px;
  border-top: 1px solid #ccc;
  padding-top: 30px;
  position: relative;
  .title {
    line-height: 30px;
    position: absolute;
    top: -15px;
    left: 30px;
    width: 60px;
    background-color: white;
    text-align: center;
  }
}
.report-item {
  width: 280px;
  display: flex;
  flex-direction: row;
  margin: 0 18px 18px 0;
  margin-left: 0;
  margin-right: 15px;
  padding: 7px;
  cursor: pointer;
  border: 1px solid #eee;
  border-radius: 10px;
  box-shadow: 0 0 5px #eee;
}

.bg-info {
  width: 64px;
  height: 64px;
  line-height: 64px;
  color: white;
  text-align: center;
  border-radius: 10px;
}

.report-item i {
  font-size: 30px;
}
.report-item:hover {
  color: rgb(30, 170, 192);
}

.buttons-wrapper {
  position: relative;
  box-sizing: border-box;
  width: 200px;
  padding-left: 15px;
  padding-top: 3px;
  display: flex;
  flex-direction: column;
}
.btn {
  border-right: 1px solid #ddd;
}
.btn:last-child {
  border-right: 0;
}
.btn:hover {
  background-color: #ddd;
}

.btn i {
  font-size: 14px;
}
.icons {
  position: absolute;
  height: 32px;
  border-radius: 5px;
  line-height: 32px;
  box-sizing: border-box;
  background-color: rgb(244, 244, 244);
  border: 1px solid #ddd;
  top: 30px;
  right: 4px;
  button {
    width: 32px;
  }
}
::v-deep .el-input-number {
  width: 50px !important;
  display: inline-block;
}
::v-deep .el-input-number .el-input {
  display: inline-block;
  width: 50px;
}
</style>
