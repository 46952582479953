import BaseService from '@/services/BaseService'
import axios from 'axios'
import utility from '@/common/utility'
import auth from '@/common/auth'

let resourceName = 'report'
let listByProjectGuidUrl = 'ListByProjectGuid'
let getReportGuidByPeriod = 'GetReportGuidByPeriodGuid'

class ReportService extends BaseService {
  listByProjectGuid (projectGuid) {
    return axios.get(utility.getFullUrl(this.resourceName, listByProjectGuidUrl), {
      params: {
        projectGuid
      },
      headers: {
        'Authorization': utility.getAuthHeader(auth.getToken())
      }
    })
  }

  getReportGuidByPeriod (periodGuid, reportName) {
    return axios.get(utility.getFullUrl(this.resourceName, getReportGuidByPeriod), {
      params: {
        periodGuid,
        reportName
      },
      headers: {
        'Authorization': utility.getAuthHeader(auth.getToken())
      }
    })
  }
}

const reportService = new ReportService(resourceName)

export default reportService
