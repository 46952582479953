import BaseService from '@/services/BaseService'
import utility from '@/common/utility'
import axios from 'axios'
import auth from '@/common/auth'

let resourceName = 'examination'
let getExaminationsPeriodsUrl = 'GetExaminationsPeriods'
let getNextUsersUrl = 'GetNextUsers'
let transferExaminationsUrl = 'TransferExaminations'
let finalSubmitUrl = 'EditFinalApproval'
let makeReportUrl = 'MakeReport'
let makeReportAdminUrl = 'MakeReportAdmin'
let makeFinancialEstimateUrl = 'MakeFinancialEstimate'
let getAddedPeriodNamesUrl = 'GetPeriodNames'
let deleteA15Url = 'DeleteA15sByExaminationGuid'
let getPushDataUrl = 'GetF02ByExaminationGuid'
let getPeriodDateUrl = 'GetExaminationsRQByProjectGuid'
let getPeriodByDateUrl = 'GetExaminationsPeriodsByRQ'
let listPageByProjectUrl = 'ListPageByProjectGuid'
let getLastPeriodGuidUrl = 'GetPeriodGuidByTenderGuid'
let excelAttachmentUrl = 'UploadExcelFile'

class ExaminationService extends BaseService {
  // 重写父类方法
  list (pageInfo, searchModel) {
    let params = {}
    Object.assign(params, pageInfo, searchModel)

    return axios.get(utility.getFullUrl(this.resourceName), {
      params: params,
      headers: {
        'Authorization': utility.getAuthHeader(auth.getToken())
      }
    })
  }

  labList (pageInfo, searchModel) {
    let params = {}
    Object.assign(params, pageInfo, searchModel)

    return axios.get(utility.getFullUrl(this.resourceName, 'GetJianLiShiYanListPage'), {
      params: params,
      headers: {
        'Authorization': utility.getAuthHeader(auth.getToken())
      }
    })
  }

  listByProject (pageInfo, searchModel) {
    let params = {}
    Object.assign(params, pageInfo, searchModel)

    return axios.get(utility.getFullUrl(this.resourceName, listPageByProjectUrl), {
      params: params,
      headers: {
        'Authorization': utility.getAuthHeader(auth.getToken())
      }
    })
  }

  // 重写父类方法
  add (periodGuid) {
    return axios.post(utility.getFullUrl(this.resourceName) + `?periodGuid=${periodGuid}`, {}, {
      headers: {
        'Authorization': utility.getAuthHeader(auth.getToken())
      }
    })
  }

  getPeriods () {
    return axios.get(utility.getFullUrl(this.resourceName, getExaminationsPeriodsUrl), {
      headers: {
        'Authorization': utility.getAuthHeader(auth.getToken())
      }
    })
  }

  getUsers (examinationGuid) {
    return axios.get(utility.getFullUrl(this.resourceName, getNextUsersUrl), {
      params: {
        examinationGuid
      },
      headers: {
        'Authorization': utility.getAuthHeader(auth.getToken())
      }
    })
  }

  transfer (userGuid, stepName, flowGuid, examinationGuid, flowSortId) {
    return axios.post(utility.getFullUrl(this.resourceName, transferExaminationsUrl), utility.getFormData({
      userGuid,
      stepName,
      flowGuid,
      examinationGuid,
      flowSortId
    }), {
      headers: {
        'Authorization': utility.getAuthHeader(auth.getToken())
      }
    })
  }

  finalSubmit (examinationGuid, status) {
    return axios.post(utility.getFullUrl(this.resourceName, finalSubmitUrl) + `?examinationGuid=${examinationGuid}&sign=${status}`, {}, {
      headers: {
        'Authorization': utility.getAuthHeader(auth.getToken())
      }
    })
  }

  labFinalSubmit (examinationGuid, status) {
    return axios.post(utility.getFullUrl(this.resourceName, 'EditFinalApprovalJianLi') + `?examinationGuid=${examinationGuid}&sign=${status}`, {}, {
      headers: {
        'Authorization': utility.getAuthHeader(auth.getToken())
      }
    })
  }

  makeReport (examinationGuid, projectGuid, tenderGuid, periodGuid, tenderName, periodName) {
    return axios.post(utility.getFullUrl(this.resourceName, makeReportUrl), utility.getFormData({
      examinationGuid,
      projectGuid,
      tenderGuid,
      periodGuid,
      tenderName,
      periodName
    }), {
      headers: {
        'Authorization': utility.getAuthHeader(auth.getToken())
      }
    })
  }

  makeReportAdmin (examinationGuid, projectGuid, tenderGuid, periodGuid, tenderName, periodName) {
    return axios.post(utility.getFullUrl(this.resourceName, makeReportAdminUrl), utility.getFormData({
      examinationGuid,
      projectGuid,
      tenderGuid,
      periodGuid,
      tenderName,
      periodName
    }), {
      headers: {
        'Authorization': utility.getAuthHeader(auth.getToken())
      }
    })
  }

  makeFinancialEstimate (examinationGuid) {
    return axios.post(utility.getFullUrl(this.resourceName, makeFinancialEstimateUrl), utility.getFormData({
      examinationGuid
    }), {
      headers: {
        'Authorization': utility.getAuthHeader(auth.getToken())
      }
    })
  }

  getAddedPeriods (tenderGuid) {
    return axios.get(utility.getFullUrl(this.resourceName, getAddedPeriodNamesUrl), {
      params: {
        tenderGuid
      },
      headers: {
        'Authorization': utility.getAuthHeader(auth.getToken())
      }
    })
  }

  deleteA15 (examinationGuid) {
    return axios.post(utility.getFullUrl(this.resourceName, deleteA15Url), null, {
      params: {
        examinationGuid
      },
      headers: {
        'Authorization': utility.getAuthHeader(auth.getToken())
      }
    })
  }

  getPushData (examinationGuid) {
    return axios.post(utility.getFullUrl(this.resourceName, getPushDataUrl), null, {
      params: {
        examinationGuid
      },
      headers: {
        'Authorization': utility.getAuthHeader(auth.getToken())
      }
    })
  }

  getPeriodDate (projectGuid) {
    return axios.get(utility.getFullUrl(this.resourceName, getPeriodDateUrl), {
      params: {
        projectGuid
      },
      headers: {
        'Authorization': utility.getAuthHeader(auth.getToken())
      }
    })
  }

  getPeriodByDate (date, projectGuid) {
    return axios.get(utility.getFullUrl(this.resourceName, getPeriodByDateUrl), {
      params: {
        rQ: date,
        projectGuid
      },
      headers: {
        'Authorization': utility.getAuthHeader(auth.getToken())
      }
    })
  }

  getLastPeriodGuid (tenderGuid) {
    return axios.get(utility.getFullUrl(this.resourceName, getLastPeriodGuidUrl), {
      params: {
        tenderGuid
      },
      headers: {
        'Authorization': utility.getAuthHeader(auth.getToken())
      }
    })
  }

  uploadExcelAttachment (examinationGuid, file) {
    const formData = new FormData()
    formData.append('file', file)
    return axios.post(utility.getFullUrl(this.resourceName, excelAttachmentUrl), formData, {
      params: {
        examinationGuid
      },
      headers: {
        'Authorization': utility.getAuthHeader(auth.getToken())
      }
    })
  }
}

const examinationService = new ExaminationService(resourceName)

export default examinationService
